/**
 * main.js
**/

$(document).ready(function() {

    function resetNavbar() {
        $('.navbar-collapse').css({'height': 1, 'overflow-x': 'hidden'});
    }

    $(".owl-carousel__fullpage").owlCarousel({
        center: true,
        loop: true,
        dragEndSpeed: 700,
        nav: true,
        navText: [
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>"
        ],
        navElement: 'button',
        dots: false,
        items: 1,
        responsive: {
            480: {
                items: 1
            },
            996: {
                items: 3
            }
        }
    });

    $(".owl-carousel__flatCentral").owlCarousel({
        center: false,
        responsiveClass:true,
        items: 5,
        loop: true,
        responsive: {
            480: {
                items: 1
            },
            996: {
                items: 1
            }
        }
    });

    // init wow

    new WOW(
        {
            mobile: false
        }
    ).init();


    // init photoswipe
    initPhotoSwipeFromDOM('.ph-gallery');

    var main = {
        body: $('body'),
        html: $('html'),
        heroBoxBackgroundColor: $('.logoHolder'),
        svgImg: $('.st0'),
        navbarLogo: $('.navbarLogo'),
        coverHeroBox: $('.coverHeroBox'),
        mainNavbar: $('.navbar--animation'),
        brandNavbar: $('.navbar-brand'),
        buttonsNavbar: $('.navbarButtons--animation'),
        navbarHeight: $('.navbar-header').height(),
        mainNavbarPosY: $('.navbar--animation').position().top,
        windowPosY: $(window).scrollTop(),
        windowHeight: $(window).height(),
        mainNavbarIsFixedToTop: false,

        introAnimation: function () {
            main.body.removeClass('active');
        },
        toggleNavbarPosition: function () {
            if (main.windowPosY >= main.mainNavbarPosY) {
                return main.mainNavbar.addClass('active');
            } else {
                return main.mainNavbar.removeClass('active');
            }
        },
        toggleLogoAnimation: function () {
            var tl = new TimelineLite();
            var animationTriggerOffsetY = main.windowHeight / 3 + main.navbarHeight;

            // Fade out fade in logo image based on window pos 
            if (main.windowPosY >= animationTriggerOffsetY) {
                tl.to(main.svgImg, .1, { opacity: 0 });
                main.mainNavbarFixedToTop = true;
            } else {
                tl.to(main.svgImg, .1, { opacity: 1 });
                main.mainNavbarFixedToTop = false;
            }

            // Show logo in navbar based on window position
            main.mainNavbarFixedToTop
                ? TweenMax.to(main.navbarLogo, .4, { bottom: 0, opacity: 1 })
                : TweenMax.to(main.navbarLogo, .4, { bottom: -20, opacity: 0 })

        },
        updateHeroCoverOpacity: function () {
            var opacityThreshHold = main.windowPosY / 1000;
            var triggerFadeInOpacityPosY = 400;

            if (main.windowPosY > triggerFadeInOpacityPosY) {
                main.coverHeroBox.css('opacity', opacityThreshHold);
            }
        },
        animateViewPortOnBtnClick: function(target) {
            var scrollDest = $(target).attr('data-scroll-dest');

            $('.navbar-nav li a').each(function(index, item) {
                $(item).removeClass('active');
            });

            $(target).addClass('active')

            main.html.animate({
                scrollTop: $(scrollDest).offset().top
            }, 1500);
        }
    }

    $('[data-scroll-dest]').click(function(event) {
        event.preventDefault();
        main.animateViewPortOnBtnClick(event.target);
    })

    $(window).scroll(function () {
        main.windowPosY = $(this).scrollTop();

        main.toggleNavbarPosition();
        main.updateHeroCoverOpacity();
    });

    $(window).resize(function () {
        main.windowHeight = $(this).height();
    });


    $.datepicker.regional['it'] = {
        closeText: 'Chiudi', // set a close button text
        currentText: 'Oggi', // set today text
        monthNames: ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno',   'Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'], // set month names
        monthNamesShort: ['Gen','Feb','Mar','Apr','Mag','Giu','Lug','Ago','Set','Ott','Nov','Dic'], // set short month names
        dayNames: ['Domenica','Luned&#236','Marted&#236','Mercoled&#236','Gioved&#236','Venerd&#236','Sabato'], // set days names
        dayNamesShort: ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'], // set short day names
        dayNamesMin: ['Do','Lu','Ma','Me','Gio','Ve','Sa'], // set more short days names
        dateFormat: 'dd/mm/yy' // set format date
    };
    
    $.datepicker.setDefaults($.datepicker.regional['it']);

    

    function init(){
        main.introAnimation();
        $( ".datepicker" ).datepicker();
        
        if($(window).width() < 992){
            resetNavbar();
        }
    }

    init();
});


var initPhotoSwipeFromDOM = function (gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements 
    // (children of gallerySelector)
    var parseThumbnailElements = function (el) {
        var thumbElements = el.childNodes,
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for (var i = 0; i < numNodes; i++) {

            figureEl = thumbElements[i]; // <figure> element

            // include only element nodes 
            if (figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            size = linkEl.getAttribute('data-size').split('x');

            // create slide object
            item = {
                src: linkEl.getAttribute('href'),
                w: parseInt(size[0], 10),
                h: parseInt(size[1], 10)
            };



            if (figureEl.children.length > 1) {
                // <figcaption> content
                item.title = figureEl.children[1].innerHTML;
            }

            if (linkEl.children.length > 0) {
                // <img> thumbnail element, retrieving thumbnail url
                item.msrc = linkEl.children[0].getAttribute('src');
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn
            items.push(item);
        }

        return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        var eTarget = e.target || e.srcElement;

        // find root element of slide
        var clickedListItem = closest(eTarget, function (el) {
            return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if (!clickedListItem) {
            return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.childNodes,
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if (childNodes[i].nodeType !== 1) {
                continue;
            }

            if (childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }
            nodeIndex++;
        }



        if (index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe(index, clickedGallery);
        }
        return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function () {
        var hash = window.location.hash.substring(1),
            params = {};

        if (hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');
        for (var i = 0; i < vars.length; i++) {
            if (!vars[i]) {
                continue;
            }
            var pair = vars[i].split('=');
            if (pair.length < 2) {
                continue;
            }
            params[pair[0]] = pair[1];
        }

        if (params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),

            getThumbBoundsFn: function (index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();

                return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
            }

        };

        // PhotoSwipe opened from URL
        if (fromURL) {
            if (options.galleryPIDs) {
                // parse real index when custom PIDs are used 
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for (var j = 0; j < items.length; j++) {
                    if (items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
            return;
        }

        if (disableAnimation) {
            options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll(gallerySelector);

    for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
};


