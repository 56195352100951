var validationApp = angular.module('form', []);
    
validationApp.controller('formCtrl', ['$scope', function($scope) {

    function setPristine() {
        $scope.signup.$setPristine();
    }

    $scope.master = {};

    $scope.update = function(user) {
        $scope.master = angular.copy(user);
    };

    $scope.reset = function() {
        $scope.user = angular.copy($scope.master);
    };

    $scope.kidsDisabled = false;

    $scope.reset();

    $scope.adulti = [
        {
            id: 0,
            label: '1 adulto',
            active: true,
            type: 'SNJ'
        },
        {
            id: 1,
            label: '2 adulti',
            active: true,
            type: 'SNJ'
        },
        {
            id: 2,
            label: '3 adulti',
            active: true,
            type: 'SNJ'
        }
    ];

    $scope.bambini = [
        {
            id: 3,
            label: 'Nessun bambino',
            active: true,
            type:'KID'
        },
        {
            id: 4,
            label: '1 bambino',
            active: true,
            type:'KID'
        },
        {
            id: 5,
            label: '2 bambini',
            active: true,
            type:'KID'
        }
    ]

    function checkPlacesAvailability(selectedVal) {
        switch(selectedVal.id){
            case '':

            case 0:
                $scope.bambini.forEach(function(bambino){
                    bambino.active = true;
                });
                break;
            case 1:
                $scope.bambini.forEach(function(bambino){
                    bambino.active = true;
                });
                break;
            case 2:
                $scope.user.selectedBambino = $scope.bambini[0];
                break;
            case 3:
                $scope.adulti.forEach(function(adulto){
                    adulto.active = true;
                });
                break;
            case 4:
                $scope.adulti.forEach(function(adulto){
                    if(adulto.id === 2){
                        adulto.active = false;
                    }
                });
                break;
            case 5:
                $scope.adulti.forEach(function(adulto){
                    adulto.id === 2 ? adulto.active = false : adulto.active = true;
                });
                break;
        }
    }

    function disableBookingPeriod() {
        /* 
            var a = data d'inizio;
            var b = data di fine;

            var lunghezzaPeriodo = b - a;


        */

        console.log('porco dio')

        var present = new Date();
        var dataInizio = new Date(2018, 10, 16);
        var dataFine = new Date(2019, 2, 31);

        /* var presentYear = present.getFullYear();
        var presentMonth = present.getMonth();
        var presentDay = present.getDate();

        var dataInizioMonth = dataInizio.getMonth();
        var dataFineMonth = dataFine.getMonth();
        var dataFineYear = dataFine.getFullYear(); */

        var presentMs = present.getTime();
        var dataInizioMs = dataInizio.getTime();
        var dataFineMs = dataFine.getTime();

        var isInOnlyAdultsStatus = function() {
            return presentMs > dataInizioMs && presentMs < dataFineMs;
        };

        if(isInOnlyAdultsStatus)
            $scope.kidsDisabled = true;
        
    }
    
    
    function submitForm() {
        alert('hey');
        console.log($scope.selectedBambino)
    }

    function checkHostsTotal(host) {
        console.log(host)
    }

    function init() {
        $scope.checkPlacesAvailability = checkPlacesAvailability;
        $scope.checkHostsTotal = checkHostsTotal;
        $scope.setPristine = setPristine;
        $scope.submitForm = submitForm;
        $scope.disableBookingPeriod = disableBookingPeriod;

        $scope.disableBookingPeriod();

    }

    init();
}])